<template>

  <carousel></carousel>

  <div class="container">

    <CatalogSearch></CatalogSearch>

    <QuickLinks></QuickLinks>

    <hr>

    <Featurette></Featurette>

    <hr>

    <EventCalendar></EventCalendar>

  </div>
</template>

<script>
import Carousel from "@/components/Carousel"
import CatalogSearch from "@/components/CatalogSearch"
import EventCalendar from "@/components/EventCalendar"
import Featurette from "@/components/Featurette"
import QuickLinks from "@/components/QuickLinks"

export default {
  name: 'Home',
  components: { Carousel, CatalogSearch, EventCalendar, Featurette, QuickLinks }
}
</script>

<style scoped>
hr {
  margin-top: 2em;
  margin-bottom: 2em;
}
</style>
