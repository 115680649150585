<template>
  <div class="container">
    <div class="row" v-for="(featurette, index) in featurettes" v-bind:key="featurette.id">
      <template v-if="index % 2 === 0">
        <div class="col f-image-container" style="">
          <img class="featurette-image" :src="featurette.image.url">
        </div>
        <div class="col featurette-text" style="text-align: left">
          <h2 class="featurette-heading">{{ featurette.header }}</h2>
          <h2><span class="text-muted">{{ featurette.sub_header}}</span></h2>
          <p class="lead">{{ featurette.message }}</p>
        </div>
        <hr class="featurette-break">
      </template>
      <template v-else>
        <div class="col featurette-text order-2 order-md-2" style="text-align: right">
          <h2 class="featurette-heading">{{ featurette.header }}</h2>
          <h2><span class="text-muted">{{ featurette.sub_header}}</span></h2>
          <p class="lead featurette-text-body">{{ featurette.message }}</p>
        </div>
        <div class="col f-image-container order-1 order-md-2">
          <img class="featurette-image" :src="featurette.image.url">
        </div>
        <hr class="featurette-break">
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "Featurette",
  data () {
    return {
      featurettes: [],
      error: null,
      headers: { 'Content-Type': 'application/json' }
    }
  },
  methods: {
    parseJSON: function (resp) {
      return (resp.json ? resp.json() : resp)
    },
    checkStatus: function (resp) {
      if (resp.status >= 200 && resp.status < 300) {
        return resp
      }
      return this.parseJSON(resp).then((resp) => {
        throw resp
      })
    }
  },
  async mounted () {
    try {
      const response = await fetch(process.env.VUE_APP_STRAPI_URL + "/featurettes", {
        method: 'GET',
        headers: this.headers
      }).then(this.checkStatus)
        .then(this.parseJSON)
      this.featurettes = response
    } catch (error) {
      this.error = error
    }
  }
}
</script>

<style scoped>

.featurette-image {
  height: 350px;
  width: 350px;
}

.featurette-text {
  align-items: center;
  margin: auto;
}

.featurette-text-body {
  font-size: 1.3em;
}

.featurette-heading{
  font-size: 3em;
}

.featurette-break{
  visibility: hidden;
}

.f-image-container{
  text-align: center;
}

h2 {
  line-height: .8em;
  font-size: 2.1em;
  font-weight: bolder;
}

.text-muted {
  opacity: 80%;
}

</style>
