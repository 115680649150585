<template>
  <div id="carousel">
    <div id="carouselIndicators" class="carousel carousel-dark slide" data-bs-ride="carousel">

      <ol class="carousel-indicators" style="text-align: center">
        <li data-bs-target="#carouselIndicators" v-for="num in image_count" v-bind:key="num.id" :data-bs-slide-to="num"></li>
      </ol>

      <div class="carousel-inner" style="align-content: center">
        <div class="carousel-item" v-for="(image, index) in image_list" v-bind:key="image.id" :class="{ 'active': index === 0 }">
          <img :src="image.url" class="d-block w-100">
        </div>
      </div>

      <a class="carousel-control-prev" href="#carouselIndicators" role="button" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </a>
      <a class="carousel-control-next" href="#carouselIndicators" role="button" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Carousel",
  data () {
    return {
      image_list: [],
      image_count: null,
      error: null,
      headers: { 'Content-Type': 'application/json' }
    }
  },
  methods: {
    parseJSON: function (resp) {
      return (resp.json ? resp.json() : resp)
    },
    checkStatus: function (resp) {
      if (resp.status >= 200 && resp.status < 300) {
        return resp
      }
      return this.parseJSON(resp).then((resp) => {
        throw resp
      })
    }
  },
  async mounted () {
    try {
      const response = await fetch(process.env.VUE_APP_STRAPI_URL + "/carousels", {
        method: 'GET',
        headers: this.headers
      }).then(this.checkStatus)
        .then(this.parseJSON)
      this.image_list = response[0].images
      this.image_count = this.image_list.length
    } catch (error) {
      this.error = error
    }
  }
}

</script>

<style scoped>
.carousel-inner img{
  width: auto;
  height: 420px;
  object-fit: contain;
}
</style>
