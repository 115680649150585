<template>
  <div class="quicklinks">
    <div class="row">
      <div class="col-lg-4 block">
              <span class="fa-stack fa-4x">
                  <a href="https://rauchholzlibrary.biblionix.com/catalog/" target="_blank">
                      <i class="fa fa-square fa-stack-2x" style="color:forestgreen"></i>
                      <i class="fas fa-book fa-stack-1x fa-inverse faa-tada animated-hover"></i>
                  </a>
              </span>
        <h2>Online Catalog</h2>
        <p>Explore our online catalog to find your next favorite book or movie.</p>
        <p>
          <a href="https://rauchholzlibrary.biblionix.com/catalog/" target="_blank" class="btn btn-success"
             role="button">Catalog &raquo;</a>
        </p>
      </div>
      <div class="col-lg-4 block">
              <span class="fa-stack fa-4x">
                  <router-link :to=" { name: 'Calendar' }">
                      <i class="fa fa-square fa-stack-2x" style="color:orange"></i>
                      <i class="fas fa-calendar-alt fa-stack-1x fa-inverse faa-tada animated-hover"></i>
                  </router-link>
              </span>
        <h2>Events</h2>
        <p>View upcoming events!</p>
        <p><router-link :to=" { name: 'Calendar' }"><a class="btn btn-success" role="button">View Calendar &raquo;</a></router-link></p>
      </div>
      <div class="col-lg-4 block">
              <span class="fa-stack fa-4x">
                  <a href="http://fuelyourmind.lib.overdrive.com/D3398C5C-5E54-44D2-8607-70F9E54AF115/10/50/en/Default.htm"
                     target="_blank">
                      <i class="fa fa-square fa-stack-2x" style="color:red"></i>
                      <i class="fas fa-tablet-alt fa-stack-1x fa-inverse faa-tada animated-hover"></i>
                  </a>
              </span>
        <h2>Libby</h2>
        <p>eBooks, Audiobooks and Digital Magazines</p>
        <p><a class="btn btn-success"
              href="http://fuelyourmind.lib.overdrive.com/D3398C5C-5E54-44D2-8607-70F9E54AF115/10/50/en/Default.htm"
              target="_blank" role="button">View Details &raquo;</a></p>
      </div>

    </div>
    <!-- Four columns of text below the carousel END-->

    <!-- Three columns of text -->
    <div class="row" style="text-align: center">
      <div class="col-lg-4 block">
              <span class="fa-stack fa-4x">
                  <a href="http://MIActivityPass.org" target="_blank">
                      <i class="fa fa-square fa-stack-2x" style="color:red"></i>
                      <i class="fas fa-parachute-box fa-stack-1x fa-inverse faa-tada animated-hover"></i>
                  </a>
              </span>
        <h2>MI Activity Pass</h2>
        <p>Check out a pass for one of Michigan’s cultural attractions</p>
        <p><a class="btn btn-success" href="http://MIActivityPass.org"
              target="_blank" role="button">View Details &raquo;</a></p>
      </div>
      <div class="col-lg-4 block">
              <span class="fa-stack fa-4x">
                  <router-link :to=" { name: 'Databases' }">
                      <i class="fa fa-square fa-stack-2x" style="color:orange"></i>
                      <i class="fas fa-user-md fa-stack-1x fa-inverse faa-tada animated-hover"></i>
                  </router-link>
              </span>
        <h2>Databases</h2>
        <p>Free access to Merck Manual and Merck Veterinary Manual</p>
        <p><router-link :to=" { name: 'Databases' }"><a class="btn btn-success" role="button">View Details &raquo;</a></router-link></p>
      </div>
      <div class="col-lg-4 col-sm-6 block">
              <span class="fa-stack fa-4x">
                  <a href="http://mel.org/melcat" target="_blank">
                      <i class="fa fa-square fa-stack-2x" style="color:forestgreen"></i>
                      <i class="fas fa-shipping-fast fa-stack-1x fa-inverse faa-passing animated-hover"></i>
                  </a>
              </span>
        <h2>MeLCat</h2>
        <p>Borrow books from libraries all across Michigan</p>
        <p>
          <a class="btn btn-success" href="http://mel.org/melcat" target="_blank" role="button">Catalog
            &raquo;</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'QuickLinks',
  components: {
  }
}
</script>

<style scoped>
.quicklinks {
  text-align: center;
  padding-top: 2em;
  padding-bottom: 2em;
}

</style>
