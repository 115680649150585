<template>
  <div class="catalog-search">
    <form action="https://rauchholzlibrary.biblionix.com/" target="_blank">
      <div class="form-group">
        <h1 class="text-center" for="InputTitle">Catalog Search</h1>
        <input type="text" class="form-control" name="search" placeholder="Enter Your Search Here!">
      </div>
      <div id="catalog-button">
        <button type="submit" class="btn btn-primary btn-center">Submit</button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "CatalogSearch"
}
</script>

<style scoped>
.catalog-search {
  padding-top: 2em;
  text-align: center;
}

#catalog-button {
  padding-top: 1em;
}
</style>
